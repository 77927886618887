.scanQr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.scanQr__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;
    max-height: 585px;
    text-align: center;
}

.scanQr__inner h2, 
.scanQr__inner p {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
}

.scanQr__title {
    max-width: 204px;
}

.scanQr__download {
    max-width: 284px;
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .scanQr__inner {
        max-height: 565px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .scanQr__inner {
        max-height: 458px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .scanQr__inner {
        max-height: 368px;
    }
}