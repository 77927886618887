:root {
    /**
    * Colors 
    */
    --darkPurple: #2E2342;
    --brightPink: #FF3066;
    --fadedYellow: #FFB400;
    --white: #FFFFFF;
    --lightGrey: #9D9D9D;
    --darkGrey: #2E2E2E;

    
    /**
    * Spacing 
    */
    --px4: 4px;
    --px8: 8px;


    /**
    * Typography 
    * Base
    */
    --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-style: normal;
    --font-weight-bold: bold;
    --font-weight-normal: normal;
    --font-letter-space-normal: normal;
    --font-letter-space-02: 0.2px; /*Title/ Body */

    /* Type Scale */
    --title-font-size: 24px;
    --title-line-height: 167%;

    --body-font-size: 20px;
    --body-line-height: 150%;

    --footer-font-size: 12px;
    --footer-line-height: 133%;

    --menu-font-size: 16px;
    --menu-line-height: 150%;

    --button-font-size: 16px;
    --button-line-height: 150%;


    --title:  
        var(--font-style)
        var(--font-weight-bold)
        var(--title-font-size)/
        var(--title-line-height)
        var(--font-family);

    --body:  
        var(--font-style)
        var(--font-weight-normal)
        var(--body-font-size)/
        var(--body-line-height)
        var(--font-family);

    --footer:  
        var(--font-style)
        var(--font-weight-normal)
        var(--footer-font-size)/
        var(--footer-line-height)
        var(--font-family);

    --menu:  
        var(--font-style)
        var(--font-weight-bold)
        var(--menu-font-size)/
        var(--menu-line-height)
        var(--font-family);

    --button:  
        var(--font-style)
        var(--font-weight-bold)
        var(--button-font-size)/
        var(--button-line-height)
        var(--font-family);
}


/* iPhone SE */
@media ( max-height: 568px ) {
    :root {
        /* Type Scale */
        --title-font-size: 20px;
        --title-line-height: 150%;

        --body-font-size: 16px;
        --body-line-height: 150%;

        --button-font-size: 12px;
        --button-line-height: 133%;
    }
}

.display__none {
    display: none !important;
}
