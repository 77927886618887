.alert {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 15px;
    width: 362px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.alert__inner {
    width: inherit;
    height: inherit;
    padding: 32px;
}

.alert__message {
    margin-bottom: 36px;
}

.alert__btn {
    padding: 10px 44px;
    border: 1px solid var(--darkPurple);
    border-radius: 15px;    
    font: var(--button);
    letter-spacing: var(--font-letter-space-normal);
}

.alert__title {
    font: var(--button);
    color: var(--brightPink);
    margin-bottom: 8px;
}

.alert__btn-wrapper {
    width: 100%;
    height: auto;
    display: flex;
}

.alert__overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: .5;
}