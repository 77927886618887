/* .header {
  display: none !important;
}
.main {
  margin-top: 0;
  height: 100vh;
} */

.main video {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.qr__wrapper {
  touch-event: none;
  position: relative;
}

svg.qr__svg {
  touch-event: none;
  position: absolute;
  top: 0;  
  left: 0;
  width: 100vw;
  height: 100vh;
}

.qr__line {
  touch-event: none;
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 300px;
  width: 320px;
  opacity: .8;
}

.qr__line .qr__line--inner {
  touch-event: none;
  position: relative;
  height: inherit;
  width: inherit;
}

.qr__line .qr__line--inner:before {
  touch-event: none;
  content: "";
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 320px;
  height: 4px;
  background-color: var(--brightPink);
  animation: qrAnimation 5s ease 0s infinite none;
}

@keyframes qrAnimation {
  0% {
    top: 0%;
  }
  50% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
}

