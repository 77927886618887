.image {
    height: auto;
    width: 100%;
    max-width: 160px;
}

.image__full_width {
    max-width: 100% !important;
}

.image__max_high--400px {
    object-fit: cover;
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .image__max_high--400px {
        max-height: 400px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .image__max_high--400px {
        max-height: 292px;
    }
    .image__iphone {
        max-height: 264px;
        object-fit: contain;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .image__max_high--400px {
        max-height: 216px;
    }
    .image__iphone {
        max-height: 202px;
        object-fit: contain;
    }   
}