.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.login__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;
    max-height: 565px;
    text-align: center;
}

.login__title {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;    
    padding-bottom: 16px;
}

.login__text {
    font:var(--footer);
    color: var(--lightGrey);
    letter-spacing: var(--font-letter-space-normal);
    width: 100%;
    max-width: 284px;
}

.login__text a {
    color: var(--darkPurple);
    text-decoration: none;
}

.button__container {
    height: 200px;
    //display: flex;
    flex-direction: column;
    align-items: center;
}

.button__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 224px;    
    padding-bottom: 16px;
}

.button_op .button__icon {
    transform: scale(1.2);
}

.button__more {
    display: block;
    margin: 32px auto 16px;
    padding: 10px 44px;
    border-radius: 100px;
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .login__inner {
        max-height: 565px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .login__inner {
        max-height: 488px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .login__inner {
        max-height: 388px;
    }
}
