.userinfo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.userinfo__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;    
    max-height: 508px;
    text-align: center;
}

.userinfo__title {
    font:var(--title);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
    margin-bottom: calc(var(--px8)*2);
}

.userinfo__text {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
    max-width: 284px;
}


form input {
    width: 288px;
    border:none;
    border-bottom: 1px solid var(--darkPurple);
    padding: 5px 10px;
    outline: none;
    text-align: center;
    font: var(--body);
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .userinfo__inner {
        max-height: 508px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .userinfo__inner {
        max-height: 428px;
    }

    .userinfo__inner .housewarming {
        max-height: 152px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .userinfo__inner {
        max-height: 368px;
    }

    .userinfo__inner .housewarming {
        max-height: 132px;
    }

    form input {
        width: 264px;
    }
}
