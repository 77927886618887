.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.welcome__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;    
    max-height: 508px;
    text-align: center;
}

.welcome__title {
    font:var(--title);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
}

.welcome__text {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
    max-width: 284px;
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .welcome__inner {
        max-height: 508px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .welcome__inner {
        max-height: 428px;
    }

    .welcome__inner .housewarming {
        max-height: 152px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .welcome__inner {
        max-height: 368px;
    }

    .welcome__inner .housewarming {
        max-height: 132px;
    }
}