.menu {
    position: fixed;
    z-index: 9999;
    top: 72px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 72px);
    background: var(--white);
}

.menu__inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.menu__list {
    height: 100%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.menu__list-item-link {
    font:var(--menu);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    text-decoration: none;
}

.menu__list-item-link--active {
    font:var(--menu);
    color: var(--brightPink);
    letter-spacing: var(--font-letter-space-02);
    text-decoration: none;
}