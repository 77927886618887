.button {
    border: 0;
}

.button__text {
    font:var(--button);
    letter-spacing: var(--font-letter-space-normal);
    text-align: center;
    padding: calc(var(--px4) * 3) calc(var(--px8) * 4);
    border-radius: 15px;
}

.button__icon {
    height: 56px;
    width: 56px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.button__primary {
    background: var(--fadedYellow);
    color: var(--darkPurple);
}

.button__secondary {
    background: var(--darkPurple);
    color: var(--white);
}


.button__facebook {
    background: var(--white);
    border: 1px solid #3B5998;
}

.button__apple {
    background: var(--white);
    border: 1px solid #000000;
}

.button__apple svg {
    margin: -2px 0 0 -2px;
}

.button__google {
    background: var(--white);
    border: 1px solid #EA4335;
}

.button__upload {
    background: var(--white);
    font:var(--body);
    letter-spacing: var(--font-letter-space-02);
    text-align: center;
    border-radius: 15px;
    width: 100%;
    max-width: 340px;
    height: 100%;
    max-height: 116px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.button__upload span {
    position: relative;
}

.button__upload span:before {
    content: "";
    position: absolute;
    top: 0;
    left: -36px;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='24' viewBox='0 0 19 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.39024 15.5268C10.0305 15.5268 10.532 15.0039 10.532 14.3957V4.52526L10.436 3.0527L10.9802 3.83166L12.218 5.15483C12.4101 5.36825 12.6768 5.48563 12.9649 5.48563C13.4878 5.48563 13.9573 5.09081 13.9573 4.52526C13.9573 4.23715 13.8506 4.02373 13.6479 3.83166L10.2973 0.59843C9.99848 0.310321 9.69969 0.203613 9.39024 0.203613C9.08079 0.203613 8.79268 0.310321 8.4939 0.59843L5.13262 3.83166C4.92988 4.02373 4.82317 4.23715 4.82317 4.52526C4.82317 5.09081 5.28201 5.48563 5.80488 5.48563C6.09299 5.48563 6.37043 5.36825 6.5625 5.15483L7.8003 3.83166L8.34451 3.04203L8.24847 4.52526V14.3957C8.24847 15.0039 8.75 15.5268 9.39024 15.5268ZM3.54268 23.7966H15.2271C17.5213 23.7966 18.7698 22.5588 18.7698 20.2859V10.5649C18.7698 8.29203 17.5213 7.04355 15.2271 7.04355H12.6235V9.59386H14.9497C15.7713 9.59386 16.2195 9.99934 16.2195 10.8743V19.9765C16.2195 20.8515 15.7713 21.257 14.9497 21.257H3.80945C2.9878 21.257 2.5503 20.8515 2.5503 19.9765V10.8743C2.5503 9.99934 2.9878 9.59386 3.80945 9.59386H6.19969V7.04355H3.54268C1.24848 7.04355 0 8.29203 0 10.5649V20.2859C0 22.5588 1.24848 23.7966 3.54268 23.7966Z' fill='%23FF3066'/%3E%3C/svg%3E%0A");
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
}

.button__logout {
    background: transparent;
    color: var(--lightGrey);
    font:var(--body);
    letter-spacing: var(--font-letter-space-02);
    text-align: center;
}

.button__ghost {
    background: transparent;
    border: 1px solid var(--darkPurple);
    color: var(--darkPurple);
    font:var(--button);
    letter-spacing: var(--font-letter-space-normal);
    text-align: center;
}

.button__disabled {
    pointer-events: none !important;
    opacity: 10%;
}


/* iPhone X */
@media ( max-height: 1125px ) {
    .button__upload {
        max-height: 116px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .button__upload {
        max-height: 100px;
    }

    .button__icon {
        transform: scale(.9);
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .button__upload {
        max-height: 78px;
        max-width: 90%;
    }

    .button__icon {
        transform: scale(.8);
    }
}