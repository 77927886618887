.footer {
    height: 72px;
    width: 100vw;
    background: var(--white);
    box-shadow: 0px -4px 2px rgba(0, 0, 0, 0.05);
}

.footer__inner {
    height: 100%;
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer p {
    font:var(--footer);
    color: var(--lightGrey);
    letter-spacing: var(--font-letter-space-normal);
}