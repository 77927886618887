.guest {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 164px);
}

.guest__header {
    background: url('../../assets/images/prt_party_background.jpg');
    width: 100%;
    height: 164px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.guest__header-title {
    font:var(--body);
    color: var(--white);
    letter-spacing: var(--font-letter-space-normal);
}

.guest__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: 100%;
    max-height: 800px;
    text-align: center;
}

.guest__title {
    font:var(--title);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
}

.guest__text {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
    max-width: 284px;
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .guest__inner {
        max-height: 400px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .guest {
        height: calc(100% - 124px);
    }

    .guest__header {
        height: 124px;
    }

    .guest__inner {
        max-height: 302px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .guest {
        height: calc(100% - 108px);
    }

    .guest__header {
        height: 108px;
    }

    .guest__inner {
        max-height: 246px;
    }
}