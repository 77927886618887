.photoUpload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.photoUpload__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    height: inherit;
    text-align: center;
}

.photoUpload__inner h2, 
.photoUpload__inner p {
    font:var(--body);
    color: var(--darkPurple);
    letter-spacing: var(--font-letter-space-02);
    width: 100%;
}

.photoUpload__title {
    max-width: 204px;
}

.photoUpload__loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, .9);
}

.photoUpload__loading img {
    width: 100px;
    height: auto;
}

.hidden {
    display: none !important;
}

.button__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.button__wrapper button:not(:last-child) {
    margin-right: calc(var(--px8) * 3);
}

/* iPhone X */
@media ( max-height: 1125px ) {
    .photoUpload__inner {
        max-height: 585px;
    }
}

/* iPhone 6-8 */
@media ( max-height: 750px ) {
    .photoUpload__inner {
        max-height: 432px;
    }
}

/* iPhone SE */
@media ( max-height: 568px ) {
    .photoUpload__inner {
        max-height: 348px;
    }
}