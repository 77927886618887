.header {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    height: 72px;
    width: 100vw;
    background: var(--white) !important;
    box-shadow: 0px 2px 2px rgba(46, 35, 66, 0.1);
}

.header__inner {
    height: 100%;
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/**
* Hamburger
* Default state
*/
.header__hamburger {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
}

.header__hamburger  .header__hamburger--line {
    position: relative;
    width: 24px;
    height: 3px;
    background: var(--darkPurple);
}

.header__hamburger .header__hamburger--line:before,
.header__hamburger .header__hamburger--line:after {
    content: "";
    position: absolute; 
    left: 0;
    width: 24px;
    height: 3px;
    background: var(--darkPurple);
}

.header__hamburger .header__hamburger--line:before {
    top: -8px;
}

.header__hamburger .header__hamburger--line:after {
    top: 8px;
}

/* Hamburger Open */
.header__hamburger--line,
.header__hamburger--line:before,
.header__hamburger--line:after {
  transition: all .2s ease;
}

.header__hamburger--open  .header__hamburger--line {
    position: relative;
    width: 24px;
    height: 3px;
    background: transparent;
}

.header__hamburger--open .header__hamburger--line:before,
.header__hamburger--open .header__hamburger--line:after {
    content: "";
    position: absolute; 
    left: 0;
    top: 50%;
    width: 24px;
    height: 3px;
    background: var(--darkPurple);
}

.header__hamburger--open .header__hamburger--line:before {  
    transform: rotate(45deg);
}

.header__hamburger--open .header__hamburger--line:after {
    transform: rotate(-45deg);
}
